import React, { useState, useEffect } from 'react';

function FillInTheBlank({ word, fillBlank, allWords, onAnswer }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);

  useEffect(() => {
    const shuffled = allWords.filter(w => w !== word).sort(() => 0.5 - Math.random());
    const randomOptions = shuffled.slice(0, 3);
    setOptions([word, ...randomOptions].sort(() => 0.5 - Math.random()));
    setSelectedOption('');
    setIsCorrect(null);
  }, [word, allWords]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    const correct = option === word;
    setIsCorrect(correct);
    setTimeout(() => {
      onAnswer(correct);
    }, 1000); // Delay to show feedback before moving to next card
  };

  return (
    <div className="fill-in-the-blank">
      <p>{fillBlank.replace('__________', '________')}</p>
      <div className="options">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleOptionSelect(option)}
            className={selectedOption === option ? 'selected' : ''}
            disabled={isCorrect !== null}
          >
            {option}
          </button>
        ))}
      </div>
      {isCorrect !== null && (
        <p className={isCorrect ? 'correct' : 'incorrect'}>
          {isCorrect ? 'Correct!' : 'Incorrect. The correct answer is: ' + word}
        </p>
      )}
    </div>
  );
}

export default FillInTheBlank;
