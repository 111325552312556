import React, { useState } from 'react';

function Flashcard({ word, definition, example }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`flashcard ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
      <div className="flashcard-inner">
        <div className="flashcard-front">
          <h2>{word}</h2>
        </div>
        <div className="flashcard-back">
          <p><strong>Definition:</strong> {definition}</p>
          {example && <p><strong>Example:</strong> {example}</p>}
        </div>
      </div>
    </div>
  );
}

export default Flashcard;
