import React, { useState, useEffect, useCallback } from 'react';
import Flashcard from './Flashcard';
import FillInTheBlank from './FillInTheBlank';
import StudyMode from './StudyMode';
import HelpModal from './HelpModal';
import './App.css';

function App() {
  const [currentDataSource, setCurrentDataSource] = useState('vocab');
  const [currentTopic, setCurrentTopic] = useState('');
  const [currentTerms, setCurrentTerms] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isStudyMode, setIsStudyMode] = useState(false);
  const [studyResults, setStudyResults] = useState(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [showFillInTheBlank, setShowFillInTheBlank] = useState(false);
  const [vocab, setVocab] = useState({});
  const [terms, setTerms] = useState({});
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [refreshKey, setRefreshKey] = useState(0);
  const [fillInTheBlankScore, setFillInTheBlankScore] = useState({ correct: 0, total: 0 });

  const fetchData = useCallback(async () => {
    try {
      const timestamp = new Date().getTime();
      const vocabResponse = await fetch(`/data/vocab.json?t=${timestamp}`);
      const vocabData = await vocabResponse.json();
      setVocab(vocabData);

      const termsResponse = await fetch(`/data/terms.json?t=${timestamp}`);
      const termsData = await termsResponse.json();
      setTerms(termsData);

      setLastUpdated(Date.now());
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, refreshKey]);

  useEffect(() => {
    const data = currentDataSource === 'vocab' ? vocab : terms;
    const topics = Object.keys(data);
    if (topics.length > 0) {
      setCurrentTopic(prevTopic => {
        // If the current topic doesn't exist in the new data, reset to the first topic
        return topics.includes(prevTopic) ? prevTopic : topics[0];
      });
      setCurrentTerms(Object.entries(data[currentTopic] || data[topics[0]] || {}));
    }
  }, [currentDataSource, vocab, terms, currentTopic, lastUpdated]);

  const handleNextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % currentTerms.length);
  };

  const handlePrevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + currentTerms.length) % currentTerms.length);
  };

  const handleTopicChange = (e) => {
    const newTopic = e.target.value;
    setCurrentTopic(newTopic);
    const data = currentDataSource === 'vocab' ? vocab : terms;
    setCurrentTerms(Object.entries(data[newTopic]));
    setCurrentIndex(0);
  };

  const handleDataSourceChange = (e) => {
    setCurrentDataSource(e.target.value);
    setCurrentIndex(0);
  };

  const shuffleCards = () => {
    setCurrentTerms(prevTerms => {
      const shuffled = [...prevTerms];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    });
    setCurrentIndex(0);
  };

  const handleStudyModeToggle = () => {
    setIsStudyMode(!isStudyMode);
    setStudyResults(null);
  };

  const handleStudyFinish = (scores) => {
    setStudyResults(scores);
    setIsStudyMode(false);
  };

  const toggleHelpModal = () => {
    setIsHelpModalOpen(!isHelpModalOpen);
  };

  const toggleFillInTheBlank = () => {
    setShowFillInTheBlank(prev => !prev);
    setFillInTheBlankScore({ correct: 0, total: 0 }); // Reset score when toggling
  };

  const handleFillInTheBlankAnswer = (isCorrect) => {
    setFillInTheBlankScore(prev => ({
      correct: isCorrect ? prev.correct + 1 : prev.correct,
      total: prev.total + 1
    }));
    handleNextCard();
  };

  const currentWord = currentTerms[currentIndex]?.[1] || {};
  const progress = currentTerms.length > 0 ? ((currentIndex + 1) / currentTerms.length) * 100 : 0;

  const allWords = Object.keys(currentDataSource === 'vocab' ? vocab[currentTopic] || {} : terms[currentTopic] || {});

  const renderFlashcard = () => {
    if (currentDataSource === 'vocab') {
      return (
        <Flashcard
          word={currentWord.word || ''}
          definition={currentWord.definition || ''}
          example={currentWord.example || ''}
        />
      );
    } else {
      return (
        <Flashcard
          word={currentTerms[currentIndex]?.[0] || ''}
          definition={typeof currentTerms[currentIndex]?.[1] === 'string' ? currentTerms[currentIndex][1] : ''}
          example={null}
        />
      );
    }
  };

  const handleRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Flashcard Learning Tool</h1>
        <button className="help-button" onClick={toggleHelpModal}>?</button>
      </header>
      <main className="App-main">
        <div className="control-panel">
          <select value={currentDataSource} onChange={handleDataSourceChange} className="select-input">
            <option value="vocab">Vocabulary</option>
            <option value="terms">Terms</option>
          </select>
          <select value={currentTopic} onChange={handleTopicChange} className="select-input">
            {Object.keys(currentDataSource === 'vocab' ? vocab : terms).map((topic) => (
              <option key={topic} value={topic}>
                {topic}
              </option>
            ))}
          </select>
          <button onClick={handleStudyModeToggle} className="mode-button">
            {isStudyMode ? 'Exit Study Mode' : 'Enter Study Mode'}
          </button>
          {!isStudyMode && currentDataSource === 'vocab' && (
            <button onClick={toggleFillInTheBlank} className="mode-button">
              {showFillInTheBlank ? 'Show Flashcard' : 'Show Fill in the Blank'}
            </button>
          )}
        </div>
        {isStudyMode ? (
          <StudyMode terms={currentTerms} onFinish={handleStudyFinish} />
        ) : (
          currentTerms.length > 0 && (
            <div className="flashcard-container">
              <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
              </div>
              {currentDataSource === 'vocab' && showFillInTheBlank ? (
                <>
                  <FillInTheBlank
                    key={currentWord.word || ''}
                    word={currentWord.word || ''}
                    fillBlank={currentWord.fill_blank || ''}
                    allWords={allWords}
                    onAnswer={handleFillInTheBlankAnswer}
                  />
                  <p className="fill-in-blank-score">
                    Score: {fillInTheBlankScore.correct} / {fillInTheBlankScore.total}
                  </p>
                </>
              ) : (
                renderFlashcard()
              )}
              <div className="navigation">
                <button onClick={handlePrevCard} className="nav-button">Previous</button>
                <button onClick={handleNextCard} className="nav-button">Next</button>
                <button onClick={shuffleCards} className="nav-button">Shuffle</button>
              </div>
              <p className="card-count">Card {currentIndex + 1} of {currentTerms.length}</p>
            </div>
          )
        )}
      </main>
      {studyResults && (
        <div className="study-results">
          <h2>Study Results</h2>
          <p>Easy: {Object.values(studyResults).filter(score => score === 1).length}</p>
          <p>Medium: {Object.values(studyResults).filter(score => score === 2).length}</p>
          <p>Hard: {Object.values(studyResults).filter(score => score === 3).length}</p>
        </div>
      )}
      <HelpModal isOpen={isHelpModalOpen} onClose={toggleHelpModal} />
    </div>
  );
}

export default App;