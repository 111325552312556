import React from 'react';

function HelpModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>How to Use the Flashcard Learning Tool</h2>
        <h3>Regular Mode</h3>
        <p>
          - Click on a flashcard to flip it and reveal the definition.<br />
          - Use the "Previous" and "Next" buttons to navigate through the cards.<br />
          - The progress bar shows your position in the current deck.
        </p>
        <h3>Study Mode</h3>
        <p>
          - Click "Enter Study Mode" to begin a study session.<br />
          - You'll see one term at a time.<br />
          - Click "Show Answer" to reveal the definition.<br />
          - Rate your knowledge of the term as "Easy", "Medium", or "Hard".<br />
          - After rating, you'll move to the next term automatically.<br />
          - Once you've reviewed all terms, you'll see a summary of your results.
        </p>
        <h3>Additional Features</h3>
        <p>
          - Use the dropdown menu to switch between different topics.<br />
          - Click "Shuffle Cards" to randomize the order of the flashcards.
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default HelpModal;
