import React, { useState } from 'react';

function StudyMode({ terms, onFinish }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [scores, setScores] = useState({});

  const handleShowAnswer = () => setShowAnswer(true);

  const handleScore = (score) => {
    setScores({ ...scores, [currentIndex]: score });
    if (currentIndex < terms.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setShowAnswer(false);
    } else {
      onFinish(scores);
    }
  };

  if (currentIndex >= terms.length) {
    return <div>Study session complete!</div>;
  }

  const currentTerm = terms[currentIndex];
  const word = currentTerm[0];
  const definition = typeof currentTerm[1] === 'object' ? currentTerm[1].definition : currentTerm[1];

  return (
    <div className="study-mode">
      <h2>{word}</h2>
      {!showAnswer && <button onClick={handleShowAnswer}>Show Answer</button>}
      {showAnswer && (
        <div>
          <p>{definition}</p>
          <button onClick={() => handleScore(1)}>Easy</button>
          <button onClick={() => handleScore(2)}>Medium</button>
          <button onClick={() => handleScore(3)}>Hard</button>
        </div>
      )}
    </div>
  );
}

export default StudyMode;
